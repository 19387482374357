import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItemData, MenuItemDataSteps, Location } from '../../../types';
import SidebarStep from './SidebarStep';
import { getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi';
import chatApi from '../../../api/chatApi';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useTestingStage from '../../../hooks/testingTask/useTestingStage';

interface LevelPageProps {
  menuItems?: MenuItemData[];
  nameStagesOnTask?: MenuItemDataSteps[];
}

const LevelPage: React.FC<LevelPageProps> = ({ menuItems = [], nameStagesOnTask = [] }) => {
  const history = useHistory();
  const location = useLocation();
  const [stages, setStages] = useState<MenuItemDataSteps[]>([]);
  const [lastStage, setLastStage] = useState<number>(0);
  const [loadMenuItems, setLoadMenuItems] = useState<boolean>(false);
  const currentStage = useTestingStage();
  const [currentLegendStage, setCurrentLegendStage] = useState<Location>();

  useEffect(() => {
    if (location.state && typeof location.state.legendId === 'number') {
      setCurrentLegendStage(location as Location);
    } else {
      setCurrentLegendStage(undefined);
    }
  }, [location.state?.legendId]);

  // Функция для загрузки всех этапов
  const fetchAllStages = async () => {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task || []);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  // Функция для получения истории этапов
  const getHistoryStage = async () => {
    setLoadMenuItems(true);
    try {
      const res = await chatApi.getTestingStageHistory();
      const lastActiveStageIdResult = res.result.splice(-1, 1)
     const  lastActiveStageId =  lastActiveStageIdResult[lastActiveStageIdResult.length -1]?.activeStageId || 0;
      setLastStage(lastActiveStageId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadMenuItems(false);
    }
  }

  useEffect(() => {
    getHistoryStage();
  }, [location.key]);


  // Функция обработки клика по шагу
  const onClickStep = (menuItem: MenuItemData) => {
    if (currentStage >= menuItem.activateStage) {
      history.push(menuItem.routePath);
    }
  }

  // Функция обработки клика по новым шагам 
  const onClickNewSteps = (menuItem: MenuItemDataSteps) => {
    if (menuItem.routePath === '/legend') {
      const legendId = menuItem.legend_id || stages.find((stage) => stage.routePath === '/legend')?.legend_id;

      if (!legendId) {
        console.warn('Legend ID отсутствует для данного этапа');
        return;
      }

      history.push({
        pathname: menuItem.routePath,
        state: {
          legendId: legendId
        }
      });
    } else {
      history.push(menuItem.routePath);
    }
  };

  // Функция определения статуса этапа
  const getStatus = (it: MenuItemDataSteps, currentPath: string) => {
    if (it.routePath === currentPath) {
      if (it.routePath === '/legend') {
        const actualActiveStage = stages.find(el => el.legend_id === currentLegendStage?.state?.legendId)
        if (currentLegendStage && currentLegendStage.state?.legendId === it.legend_id) {
          return 'active';
        } else if (actualActiveStage && it.activeStage < actualActiveStage?.activeStage) {
          return 'complete';
        }
      } else {
        return 'active';
      }
    }

    if (it.activeStage <= lastStage) {
      return 'complete';
    }
    return 'inactive';
  };


  if (loadMenuItems) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {nameStagesOnTask.length === 0 ? (
        menuItems.map((it, inx) => {
          let status: 'inactive' | 'active' | 'complete';
          if (lastStage >= it.activateStage) status = 'complete';
          else if (lastStage < it.activateStage) status = 'inactive';
          if (location.pathname === it.routePath) status = 'active';
          if (lastStage >= it.activateStage && it.routePath === '/examination') status = 'complete';
          if (location.pathname === '/therapy' && it.routePath === '/diagnosis') status = 'complete';
          if (location.pathname === '/finalTaskResult' && it.routePath === '/diagnosis') status = 'complete';
          if (location.pathname === '/diagnosis' && it.routePath === '/examination') status = 'complete';

          return (
            <SidebarStep
              number={inx + 1}
              key={inx + 1}
              title={it.title}
              status={status}
              onClick={() => onClickStep(it)}
            />
          );
        })
      ) : (
        nameStagesOnTask
          .sort((a, b) => a.activeStage - b.activeStage)
          .map((it, inx) => {
            let status = getStatus(it, location.pathname);
            return (
              <SidebarStep
                number={inx + 1}
                key={inx + 1}
                status={status}
                name={it.testingstage_descr}
                onClick={() => onClickNewSteps(it)}
              />
            );
          })
      )}
    </>
  );
};

export default LevelPage;

import {Button} from '@material-ui/core'
export default function TechnicalMaintenance () {
  return (
    <div style={{margin: 0, height: '100vh'}}>
      {/*<div style={{display: 'flex', gap: 15, padding: 20}}>*/}
      {/*  <Button>Проверка связи анамнезиса - исследований</Button>*/}
      {/*  <Button>Проверка на Null</Button>*/}
      {/*</div>*/}
    </div>
  )
}
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import MainLayout from '../../Layout/MainLayout';
import useIncreaseTaskStage from '../../../hooks/testingTask/useIncreaseTaskStage';
import { useHistory, useLocation } from 'react-router-dom';
import { getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi';
import chatApi from '../../../api/chatApi';
import { RoutePath } from '../../../types';
import CustomButton from '../../common/CustomButton';

/**
 * Интерфейс этапа тестирования.
 */
interface Stage {
  id: number | string;
  activeStage: number;
  routePath: string;
  routhPathCode: string;
  code?: string;
  legend_id?: boolean;
}

/**
 * Интерфейс диагностического критерия.
 */
interface DiagnosticCriterion {
  id: number | string;
  name: string;
  descriptions?: DiagnosticCriteriaDescription[];
}

/**
 * Интерфейс описания диагностического критерия.
 */
interface DiagnosticCriteriaDescription {
  id: number | string;
  diagnosticCriteriaId: number | string;
  type: string | null;
  demonstration: string;
  description: string;
  points: number; // Добавлено поле points
}

/**
 * Интерфейс ответа для фронтенда.
 */
interface FrontendAnswer {
  diagnosticCriteriaId: number | string;
  descriptionId: number | string;
  answer: boolean;
}

/**
 * Интерфейс ответа для бэкенда.
 */
interface BackendAnswer {
  diagnosticCriteriaId: number | string;
  descriptionId: number | string;
  answer: boolean;
}

const useStyles = makeStyles({
  root: {
    padding: '20px 16px',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    flexDirection: 'column',
  },
  categoryBox: {
    padding: '16px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fff',
    marginBottom: '10px'
  },
  categoryTitle: {
    marginBottom: '16px',
    fontWeight: 600,
    fontSize: '1.25rem',
    textAlign: 'center',
  },
  buttonGroup: {
    display: 'flex',
    gap: '8px',
    marginTop: '8px',
  },
  button: {
    minWidth: '40px',
    height: '32px',
    fontSize: '0.875rem',
    color: '#1876D1',
  },
});

/**
 * Хук для определения следующего этапа тестирования.
 *
 * @param {Stage[]} stages - Массив объектов этапов тестирования.
 * @returns {object} Объект с информацией о следующем этапе.
 */
const useNextStagePath = (stages: Stage[]) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return useMemo(() => {
    if (!stages || stages.length === 0) {
      return { routePath: '', routhPathCode: '', activeStage: '' };
    }
    const sortedStages = [...stages].sort((a, b) => a.activeStage - b.activeStage);
    const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);
    if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
      return {
        routePath: sortedStages[currentIndex + 1].routePath,
        routhPathCode: sortedStages[currentIndex + 1].routhPathCode,
        activeStage: sortedStages[currentIndex + 1].activeStage,
      };
    }
    return { routePath: '', routhPathCode: '', activeStage: '' };
  }, [stages, currentPath]);
};

/**
 * Компонент страницы анкеты.
 *
 * @returns {JSX.Element} Компонент страницы анкеты.
 */
const QuestionnairePage: React.FC = (): JSX.Element => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const classes = useStyles();

  const [stages, setStages] = useState<Stage[]>([]);
  const { routhPathCode, activeStage } = useNextStagePath(stages);
  const [increaseLoading, increase, setAsyncTaskStage] = useIncreaseTaskStage(
    RoutePath[routhPathCode],
    activeStage
  );
  const [manualActiveStage, setManualActiveStage] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<DiagnosticCriterion[]>([]);
  const [descriptions, setDescriptions] = useState<DiagnosticCriteriaDescription[]>([]);
  const [answers, setAnswers] = useState<FrontendAnswer[]>([]);
  const [title, setTitle] = useState('');
  const [isOpenScale, setIsOpenScale] = useState<boolean>(false); // Управление отображением таблиц

  const history = useHistory();
  const location = useLocation();

  const scaleTitle = "Отметьте пункт, соответствующий проявлению, имевшему место на момент осмотра или в течении 10 предшествовавших осмотру дней";

  /**
   * Обработка выбора ответа пользователем.
   *
   * @param {number | string} diagnosticCriteriaId - Идентификатор диагностического критерия.
   * @param {number | string} descriptionId - Идентификатор описания критерия.
   * @param {'yes' | 'no'} userChoice - Выбор пользователя: 'Да' или 'Нет'.
   */
  function handleAnswer(
    diagnosticCriteriaId: number | string,
    descriptionId: number | string,
    userChoice: 'yes' | 'no'
  ) {
    const newAnswer: FrontendAnswer = {
      diagnosticCriteriaId,
      descriptionId,
      answer: userChoice === 'yes',
    };
    console.log('newAnswer', newAnswer);
    setAnswers((prev) => {
      const existingIndex = prev.findIndex(
        (ans) =>
          ans.diagnosticCriteriaId === diagnosticCriteriaId &&
          ans.descriptionId === descriptionId
      );

      if (existingIndex !== -1) {
        // Обновляем существующий ответ
        const updatedAnswers = [...prev];
        updatedAnswers[existingIndex] = newAnswer;
        return updatedAnswers;
      } else {
        // Добавляем новый ответ
        return [...prev, newAnswer];
      }
    });
  }

  /**
   * Логирование состояния ответов для отладки.
   */
  useEffect(() => {
    console.log('answers', answers);
  }, [answers]);

  /**
   * Установка истории этапа тестирования.
   *
   * @param {number} testingStage - Текущий этап тестирования.
   */
  async function setHistoryStage(testingStage: number) {
    try {
      await chatApi.setTestingStageHistory(testingStage);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Переход на следующий этап тестирования.
   */
  async function goToNextStage() {
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);
    if (!currentStageObj) return;

    await setHistoryStage(currentStageObj.activeStage);
    const currentActiveStage = currentStageObj.activeStage;
    const sortedNextStages = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage);
    if (sortedNextStages.length === 0) {
      console.warn('Нет доступных следующих стадий.');
      return;
    }

    const finalNextStage = sortedNextStages[0];
    if (finalNextStage.legend_id) {
      history.push(RoutePath.legend);
    } else {
      history.push(finalNextStage.routePath);
    }
  }

  /**
   * Группировка критериев с их описаниями.
   *
   * @param {DiagnosticCriterion[]} criteriaList - Список диагностических критериев.
   * @param {DiagnosticCriteriaDescription[]} descriptionsList - Список описаний критериев.
   * @returns {DiagnosticCriterion[]} Группированные данные.
   */
  function groupCriteriaWithDescriptions(
    criteriaList: DiagnosticCriterion[],
    descriptionsList: DiagnosticCriteriaDescription[]
  ): DiagnosticCriterion[] {
    return criteriaList.map(criterion => {
      const matchedDescriptions = descriptionsList.filter(
        desc => desc.diagnosticCriteriaId === criterion.id
      );
      return {
        ...criterion,
        descriptions: matchedDescriptions,
      };
    });
  }

  /**
   * Загрузка всех критериев диагностики.
   */
  async function getAllCriteria() {
    try {
      const result = await chatApi.getAllDiagnosticCriteria();
      setCriteria(result.result || []);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Загрузка всех описаний критериев диагностики.
   */
  async function getAllCriteriaDescriptions() {
    try {
      const result = await chatApi.getAllDiagnosticCriteriaDescriptions();
      setDescriptions(result.result || []);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Получение истории этапов тестирования.
   */
  async function getHistoryStage() {
    try {
      const res = await chatApi.getTestingStageHistory();
      const result = res.result.find((el: any) => el.activeStageId === 10);
      if (result && (!stages || stages.length === 0)) {
        setManualActiveStage(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Загрузка всех этапов тестирования.
   */
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
      const titleQuestionnaire = result.data.testingstage_task_on_task
        .find((oneStage: { code: string; }) => oneStage.code === 'questionnaire');

      if (titleQuestionnaire) setTitle(titleQuestionnaire.testingstage_descr);
      await getHistoryStage();
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  /**
   * Отправка ответов на бэк.
   */
  async function submitAnswers() {
    setIsOpenScale(true); // Открыть таблицы после нажатия кнопки "Отправить ответы"
    /*
    try {
      const backendAnswers: BackendAnswer[] = answers.map(({ diagnosticCriteriaId, descriptionId, answer }) => ({
        diagnosticCriteriaId,
        descriptionId,
        answer,
      }));

      await chatApi.submitAnswers(backendAnswers);
      goToNextStage();
    } catch (error) {
      console.error('Ошибка при отправке ответов:', error);
      // Можно добавить уведомление для пользователя о неудаче отправки
    }
    */
  }

  /**
   * Загрузка данных при инициализации компонента.
   */
  useEffect(() => {
    getAllCriteria();
    getAllCriteriaDescriptions();
    fetchAllStages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Группировка данных для отображения.
   *
   * @returns {DiagnosticCriterion[]} Группированные данные.
   */
  const groupedData = useMemo(() => {
    return groupCriteriaWithDescriptions(criteria, descriptions);
  }, [criteria, descriptions]);

  useEffect(() => {
    console.log('groupedData ------>', groupedData);
  }, [groupedData]);

  return (
    <MainLayout title={title}>
      <Box className={classes.root}>
        {groupedData.map((criterion) => (
          <Box key={criterion.id} className={classes.categoryBox}>
            <Typography variant="h6" className={classes.categoryTitle}>
              {criterion.name}
            </Typography>
            {criterion.descriptions &&
              Object.entries(
                criterion.descriptions.reduce<Record<string, DiagnosticCriteriaDescription[]>>(
                  (acc, desc) => {
                    const type = desc.type || scaleTitle; // Обработка случая, когда type = null
                    if (!acc[type]) {
                      acc[type] = [];
                    }
                    acc[type].push(desc);
                    return acc;
                  },
                  {}
                )
              ).map(([type, descArray]) => (
                <Box
                  key={type}
                  sx={{
                    borderBottom: '1px solid #ddd',
                    paddingBottom: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      marginBottom: 1,
                      color: '#4F90DB',
                      padding: '5px 10px',
                      textAlign: 'center',
                    }}
                  >
                    {type}
                  </Typography>
                  {type !== scaleTitle ? (
                    // Рендеринг для типов, отличных от scaleTitle
                    <>
                      {descArray.map((desc) => {
                        const lines = desc.description
                          .split('\n')
                          .map((line: string) => line.trim())
                          .filter((line: string) => line.length > 0);

                        return (
                          <Box
                            key={desc.id}
                            sx={{
                              marginBottom: 2,
                              border: '1px solid gray',
                              padding: '10px 10px',
                              borderRadius: 2,
                            }}
                          >
                            <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                              {desc.demonstration}
                            </Typography>
                            <List>
                              {lines.map((line, idx) => (
                                <ListItem
                                  key={idx}
                                  sx={{ display: 'list-item', paddingLeft: 2 }}
                                >
                                  {line}
                                </ListItem>
                              ))}
                            </List>
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '8px',
                                marginTop: '8px',
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleAnswer(criterion.id, desc.id, 'yes')}
                                sx={{
                                  backgroundColor: answers.find(
                                    (ans) => ans.diagnosticCriteriaId === criterion.id &&
                                      ans.descriptionId === desc.id &&
                                      ans.answer === true
                                  )
                                    ? '#e5f3ff'
                                    : 'transparent',
                                }}
                              >
                                ДА
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleAnswer(criterion.id, desc.id, 'no')}
                                sx={{
                                  backgroundColor: answers.find(
                                    (ans) => ans.diagnosticCriteriaId === criterion.id &&
                                      ans.descriptionId === desc.id &&
                                      ans.answer === false
                                  )
                                    ? '#fff2f2'
                                    : 'transparent',
                                }}
                              >
                                НЕТ
                              </Button>
                            </Box>
                          </Box>
                        );
                      })}
                      {/* Кнопка "Отправить ответы" под блоками с "ДА" и "НЕТ" */}
                      <Box sx={{ marginBottom: '20px' }}>
                        <CustomButton onClick={submitAnswers}>Отправить ответы</CustomButton>
                      </Box>
                    </>
                  ) : (
                    // Рендеринг для scaleTitle - одна таблица для всех descArray
                    isOpenScale && (
                      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                        <Table size="small" aria-label="points table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ fontSize: 16 }}>Баллы</TableCell>
                              <TableCell align="center" sx={{ fontSize: 16 }}>Проявление</TableCell>
                              <TableCell align="center" sx={{ fontSize: 16 }}>Определение</TableCell>
                              <TableCell align="center" sx={{ fontSize: 16 }}>Имеет место</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {descArray.map((desc) => {
                              const lines = desc.description
                                .split('\n')
                                .map((line: string) => line.trim())
                                .filter((line: string) => line.length > 0);

                              return (
                                <TableRow key={desc.id}>
                                  <TableCell align="center" sx={{ fontSize: 16 }}>
                                    {desc.points ?? '—'}
                                  </TableCell>
                                  <TableCell align="center" sx={{ fontSize: 16 }}>
                                    {desc.demonstration ?? '—'}
                                  </TableCell>
                                  <TableCell align="center" sx={{ fontSize: 16 }}>
                                    {lines.length > 0 ? (
                                      lines.map((line, idx) => (
                                        <span key={idx}>
                                          {line}
                                          <br />
                                        </span>
                                      ))
                                    ) : (
                                      '—'
                                    )}
                                  </TableCell>
                                  <TableCell align="center" sx={{ fontSize: 16 }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            answers.find(
                                              (ans) => ans.diagnosticCriteriaId === criterion.id &&
                                                ans.descriptionId === desc.id &&
                                                ans.answer === true
                                            ) ? true : false
                                          }
                                          onChange={(e) => handleAnswer(
                                            criterion.id,
                                            desc.id,
                                            e.target.checked ? 'yes' : 'no'
                                          )}
                                          color="primary"
                                        />
                                      }
                                      label={<Typography variant="body1" sx={{ fontFamily: "Helvetica", fontWeight: '400', fontSize: 14 }}>
                                        Применимо
                                      </Typography>}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                  )}
                </Box>
              ))}
          </Box>))}
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CustomButton onClick={goToNextStage}>Следующий этап</CustomButton>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default QuestionnairePage;
